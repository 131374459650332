import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static outlets = ['registration']

  connect() {
    this.registrationOutlet.bindInputs()
    this.registrationOutlet.bindSelects()
    this.registrationOutlet.bindForm()
  }
}
