import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js"
import { Modal } from "bootstrap"

export default class extends Controller {
  static targets = []

  connect() { 
    this.modal = new Modal(document.getElementById("risky_zones_modal"))
  }

  async loanMap(e){
    const element = e.currentTarget
    const risky_zones = await this.getRiskyZones(element.dataset.loanApplicationId, element.dataset.addressId, element.dataset.branchOfficeId)

    let map = new google.maps.Map(document.getElementById("risky_zones_map"), {
      zoom: 11,
      center: { lat: risky_zones.geofence_zoom.latitud, lng: risky_zones.geofence_zoom.longitude },
      mapTypeId: "roadmap",
    });
    
    // address
    const address_coords = risky_zones.address_coords
    let marker = new google.maps.Marker({
      position: new google.maps.LatLng(address_coords.latitude,address_coords.longitude),
      title: "Domicilio"
    });
    marker.setMap(map);

    const geofencePolygon = new google.maps.Polygon({
      paths: risky_zones.geofence,
      strokeColor: "#ffaa78",
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: "#ffaa78",
      fillOpacity: 0.25,
    });

    geofencePolygon.setMap(map);

    // zones
    risky_zones.risky_zones.filter((zone) => zone.type === 'geofence').forEach(zone => {
      let zonePolygon = new google.maps.Polygon({
        paths: zone.points,
        strokeColor: "#FF0000",
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: "#FF0000",
        fillOpacity: 0.35,
      });
  
      zonePolygon.setMap(map)
    })
    this.modal.show()
  }

  async getRiskyZones(loan_application_id, address_id, branch_office_id){
    const response = await get(`/admin/loan_applications/${loan_application_id}/addresses/${address_id}/risky_zones_coords?branch_office_id=${branch_office_id}`)
    if(response.ok){
      return await response.json
    }
  }
}
